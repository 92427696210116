<template>
  <v-dialog
    v-model="testEmailModal"
    max-width="650px"
    @click:outside="closeTestEmailModal()"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Send Test Email</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" class="mx-2" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="receiver"
                  label="Send to..."
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <small>* indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeTestEmailModal()"> Close </v-btn>
        <v-btn color="primary" text @click="sendTestEmail()"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      receiver: "",
    };
  },
  props: {
    testEmailModal: Boolean,
    email: Object,
  },
  methods: {
    closeTestEmailModal() {
      this.$emit("closeTestEmailModal");
    },
    async sendTestEmail() {
      if (!this.$refs.form.validate()) {
        return;
      }

      try {
        const response = await this.$http.post(
          "/campaigns/emails/" + this.email._id + "/test",
          {
            receiver: this.receiver,
          }
        );
        this.$emit("testEmailSent", response.data);
      } catch (err) {
        this.$emit("failedTestingEmail", err);
      }
    },
  },
};
</script>
