<template>
  <div class="campaign">
    <campaign-email-modal
      :email-modal="emailModal"
      :email="selectedEmail || {}"
      :campaign-id="campaign._id"
      @closeCampaignEmailModal="closeCampaignEmailModal"
      @failedSavingEmail="failedSavingEmail"
      @emailSaved="emailSaved"
    ></campaign-email-modal>
    <test-email-modal
      :test-email-modal="testEmailModal"
      :email="selectedEmail || {}"
      @closeTestEmailModal="closeTestEmailModal"
      @failedTestingEmail="failedTestingEmail"
      @testEmailSent="testEmailSent"
    ></test-email-modal>
    <v-row>
      <v-col>
        <v-card flat>
          <v-tabs v-model="selectedTab" centered fixed-tabs>
            <v-tab :href="`#tab-details`"> Campaign Details </v-tab>
            <v-tab :href="`#tab-emails`"> Campaign Emails </v-tab>
            <v-tab :href="`#tab-stats`"> Campaign Stats </v-tab>
          </v-tabs>
          <v-tabs-items v-model="selectedTab">
            <v-tab-item value="tab-details">
              <v-card-text>
                <v-container>
                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon> mdi-bullhorn-outline </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title
                          >{{ campaign.name }}
                          <v-badge
                            offset-x="0"
                            offset-y="-5"
                            overlap
                            dot
                            color="green"
                            v-if="campaign.campaignStatus === 'ACTIVE'"
                          ></v-badge>
                          <v-badge
                            offset-x="0"
                            offset-y="-5"
                            overlap
                            dot
                            color="yellow"
                            v-else-if="campaign.campaignStatus === 'DRAFT'"
                          ></v-badge>
                          <v-badge
                            offset-x="0"
                            offset-y="-5"
                            overlap
                            dot
                            color="red"
                            v-else
                          ></v-badge>
                        </v-list-item-title>
                        <v-list-item-subtitle>Name</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-btn
                        v-if="campaign && campaign.campaignStatus !== 'ACTIVE'"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="green"
                        @click="startCampaign()"
                      >
                        <v-icon>mdi-play-circle-outline</v-icon>
                      </v-btn>
                      <v-btn
                        v-else
                        class="mx-2"
                        fab
                        dark
                        small
                        color="red darken-2"
                        @click="pauseCampaign()"
                      >
                        <v-icon>mdi-pause-circle-outline</v-icon>
                      </v-btn>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon> mdi-account-star-outline </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title
                          >{{ campaign.campaignSize }}
                          <router-link
                            :to="{
                              name: 'prospects',
                              query: { campaignId: campaign._id },
                            }"
                          >
                            {{
                              `(${
                                campaign && campaign.prospects
                                  ? campaign.prospects.length
                                  : 0
                              } prospects)`
                            }}</router-link
                          >
                        </v-list-item-title>
                        <v-list-item-subtitle
                          >Campaign Size</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider inset></v-divider>

                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon> mdi-calendar-range </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title
                          >{{
                            `${formatDate(campaign.startDate)} -
                            ${formatDate(campaign.endDate)}`
                          }}
                        </v-list-item-title>
                        <v-list-item-subtitle>Run between</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon> mdi-clock-outline </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title
                          >{{
                            `${getWeekDays(campaign.sendDays)} (${
                              campaign.dailyStartTime
                            } - ${campaign.dailyEndTime})`
                          }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          >Send between</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon> mdi-map-clock-outline </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title
                          >{{ campaign.timeZone }}
                        </v-list-item-title>
                        <v-list-item-subtitle>Timezone</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider inset></v-divider>

                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon> mdi-email-fast-outline </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title
                          >{{ `${sender.fullName} &lt;${sender.user}&gt;` }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          >Sender Account</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-container>
              </v-card-text>
            </v-tab-item>
            <v-tab-item value="tab-emails">
              <v-card-text>
                <v-container>
                  <template v-for="email in campaign.emails">
                    <div v-bind:key="email._id">
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon> mdi-email-fast-outline </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title @click="editEmail($event, email)"
                            ><a href>{{
                              `Email #${email.emailNumber}`
                            }}</a></v-list-item-title
                          >
                          <v-list-item-subtitle
                            class="text--primary"
                            v-text="email.subjectLine"
                          ></v-list-item-subtitle>
                          <v-list-item-subtitle class="text--small">{{
                            email.content | striphtml
                          }}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-list-item-action-text>
                            {{
                              email.sendAfter !== 0
                                ? `${email.sendAfter / (60 * 60)} h.`
                                : "Immediately"
                            }}
                          </v-list-item-action-text>
                          <span>
                            <v-icon
                              @click="testSendEmail(email)"
                              color="gray darken-2"
                            >
                              mdi-send-check
                            </v-icon>
                            &nbsp;
                            <v-icon
                              @click="deleteEmail(email)"
                              color="red darken-2"
                            >
                              mdi-delete
                            </v-icon>
                          </span>
                        </v-list-item-action>
                      </v-list-item>

                      <v-divider inset></v-divider>
                    </div>
                  </template>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  @click="addEmail()"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-card-actions>
            </v-tab-item>
            <v-tab-item value="tab-stats">
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-card>
                        <v-card-text>
                          <v-row>
                            <v-col>
                              <v-subtitle>Sent Emails</v-subtitle>
                              <div class="text-h2" style="font-weight: 400">
                                {{ totalSent() }}
                              </div>
                            </v-col>
                            <v-col>
                              <v-subtitle>Email Opens</v-subtitle>
                              <div class="text-h2" style="font-weight: 400">
                                {{ totalOpens() }}
                              </div>
                            </v-col>
                            <v-col>
                              <v-subtitle>Replies</v-subtitle>
                              <div class="text-h2" style="font-weight: 400">
                                {{ totalReplies() }}
                              </div>
                            </v-col>
                            <v-col>
                              <v-subtitle>Leads</v-subtitle>
                              <div class="text-h2" style="font-weight: 400">
                                {{ totalLeads() }}
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-card>
                        <v-card-text>
                          <v-sheet style="position: relative">
                            <v-sparkline
                              :value="stats.map((s) => s.totalSent)"
                              :labels="
                                stats.map(
                                  (s) =>
                                    `${s.totalSent} / ${s.totalUniqueOpens} / ${s.totalReplies}`
                                )
                              "
                              color="secondary"
                              height="100"
                              padding="45"
                              line-width="2"
                              stroke-linecap="round"
                              smooth
                            >
                            </v-sparkline>
                            <v-sparkline
                              class="stackSpark"
                              style="top: 20px"
                              :value="stats.map((s) => s.totalUniqueOpens)"
                              color="primary"
                              height="100"
                              padding="45"
                              line-width="2"
                              stroke-linecap="round"
                              smooth
                            >
                            </v-sparkline>
                            <v-sparkline
                              class="stackSpark"
                              style="top: 40px"
                              :value="stats.map((s) => s.totalReplies)"
                              color="pink"
                              height="100"
                              padding="45"
                              line-width="2"
                              stroke-linecap="round"
                              smooth
                            >
                            </v-sparkline>
                          </v-sheet>
                        </v-card-text>
                        <v-card-text class="text-center">
                          <div class="text-h6">
                            Sent / Opened / Replied Trend
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="6">
                      <v-card style="padding: 64px">
                        <v-progress-circular
                          :size="100"
                          :width="15"
                          :value="openRate()"
                          color="primary"
                          class="mx-3"
                        >
                          {{ `${openRate()}%` }}
                        </v-progress-circular>
                        <v-progress-circular
                          :size="100"
                          :width="15"
                          :value="replyRate()"
                          color="pink"
                          class="mx-3"
                        >
                          {{ `${replyRate()}%` }}
                        </v-progress-circular>
                        <v-progress-circular
                          :size="100"
                          :width="15"
                          :value="conversionRate()"
                          color="teal"
                          class="mx-3"
                        >
                          {{ `${conversionRate()}%` }}
                        </v-progress-circular>
                        <v-card-text class="text-center">
                          <div class="text-h6">Open / Reply / Lead Rate</div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import CampaignEmailModal from "@/components/CampaignEmailModal.vue";
import TestEmailModal from "@/components/TestEmailModal.vue";
export default {
  components: {
    CampaignEmailModal,
    TestEmailModal,
  },
  data() {
    return {
      campaign: {},
      sender: {},
      selectedTab: "tab-details",
      emailModal: false,
      testEmailModal: false,
      selectedEmail: {},
      stats: [],
    };
  },
  created() {
    this.loadCampaign();
  },
  methods: {
    openRate() {
      return parseFloat((this.totalOpens() / this.totalSent()) * 100).toFixed(
        2
      );
    },
    replyRate() {
      return parseFloat((this.totalReplies() / this.totalSent()) * 100).toFixed(
        2
      );
    },
    conversionRate() {
      return parseFloat((this.totalLeads() / this.totalSent()) * 100).toFixed(
        2
      );
    },
    totalSent() {
      return this.stats.reduce((sum, { totalSent }) => sum + totalSent, 0);
    },
    totalOpens() {
      return this.stats.reduce(
        (sum, { totalUniqueOpens }) => sum + totalUniqueOpens,
        0
      );
    },
    totalReplies() {
      return this.stats.reduce(
        (sum, { totalReplies }) => sum + totalReplies,
        0
      );
    },
    totalLeads() {
      return this.stats.reduce((sum, { totalLeads }) => sum + totalLeads, 0);
    },
    formatDate(dt) {
      if (!dt) {
        return "";
      }
      return moment(dt).format("MM/DD/YYYY");
    },
    getWeekDays(days) {
      if (!days) {
        return "";
      }
      const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
      return days.map((d) => weekDays[d]).join(", ");
    },
    async loadCampaign() {
      try {
        const campaignResponse = await this.$http.get(
          `/campaigns/${this.$route.params.campaignId}`
        );
        this.campaign = campaignResponse.data;

        const senderResponse = await this.$http.get(
          `/domains/accounts/${this.campaign.senderAccountId}`
        );
        this.sender = senderResponse.data;

        const statsResponse = await this.$http.get(
          `/stats/${this.$route.params.campaignId}`
        );
        this.stats = statsResponse.data;
      } catch (error) {
        console.log(error);
      }
    },
    async startCampaign() {
      this.campaign.campaignStatus = "ACTIVE";
      await this.$http.put(
        `/campaigns/${this.$route.params.campaignId}`,
        this.campaign
      );
      this.loadCampaign();
    },
    async pauseCampaign() {
      this.campaign.campaignStatus = "PAUSED";
      await this.$http.put(
        `/campaigns/${this.$route.params.campaignId}`,
        this.campaign
      );
      this.loadCampaign();
    },
    addEmail() {
      this.selectedEmail = {};
      this.emailModal = true;
    },
    testSendEmail(email) {
      this.selectedEmail = email;
      this.testEmailModal = true;
    },
    async deleteEmail(email) {
      try {
        await this.$http.delete(`/campaigns/emails/${email._id}`);
        this.loadCampaign();
      } catch (error) {
        console.log(error);
      }
    },
    editEmail(e, email) {
      e.preventDefault();
      this.selectedEmail = email;
      this.emailModal = true;
    },
    closeTestEmailModal() {
      this.testEmailModal = false;
    },
    failedTestingEmail() {
      this.testEmailModal = false;
    },
    testEmailSent() {
      this.testEmailModal = false;
    },
    closeCampaignEmailModal() {
      this.emailModal = false;
    },
    failedSavingEmail() {
      this.emailModal = false;
    },
    emailSaved() {
      this.loadCampaign();
      this.emailModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.text--small {
  font-size: 12px;
}
.stackSpark {
  position: absolute;
  left: 0;
}
</style>
