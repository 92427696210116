<template>
  <v-dialog
    v-model="emailModal"
    max-width="850px"
    @click:outside="closeModal()"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{
          email.emailNumber ? `Email #${email.emailNumber}` : "Add New Email"
        }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" class="mx-2" lazy-validation>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-if="!email.replyInThread"
                  v-model="email.subjectLine"
                  label="Subject Line"
                ></v-text-field>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="2">
                <v-text-field
                  v-model="email.sendAfter"
                  label="Send after"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-select
                  v-model="timeSelection"
                  :items="timeOptions"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-switch
                  v-model="email.replyInThread"
                  label="Reply in thread"
                ></v-switch>
              </v-col>
              <v-col cols="6">
                <v-switch
                  v-model="email.includeSignature"
                  label="Include sender signature"
                ></v-switch>
              </v-col>
              <v-col cols="12">
                <template>
                  <div>
                    <tiptap-vuetify
                      v-model="email.content"
                      :extensions="extensions"
                    />
                  </div>
                </template>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <small>* indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeModal()"> Close </v-btn>
        <v-btn color="primary" text @click="saveEmail()"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  History,
} from "tiptap-vuetify";

export default {
  components: { TiptapVuetify },
  data() {
    return {
      extensions: [
        History,
        Bold,
        Italic,
        Underline,
        Strike,
        ListItem,
        BulletList,
        OrderedList,
        Link,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Blockquote,
        HardBreak,
      ],
      timeOptions: ["Minutes", "Hours", "Days"],
      timeSelection: "Hours",
    };
  },
  props: {
    emailModal: Boolean,
    email: Object,
    campaignId: String,
  },
  methods: {
    closeModal() {
      this.$emit("closeCampaignEmailModal");
    },
    async saveEmail() {
      if (!this.$refs.form.validate()) {
        return;
      }

      try {
        let response = undefined;
        if (!this.email._id) {
          response = await this.$http.post(
            `/campaigns/${this.campaignId}/emails`,
            {
              subjectLine: this.email.subjectLine,
              content: this.email.content,
              sendAfter: this.getSendAfterInSeconds(this.email.sendAfter),
              replyInThread: this.email.replyInThread,
              includeSignature: this.email.includeSignature,
              campaignId: this.campaignId,
            }
          );
        } else {
          response = await this.$http.put(
            `/campaigns/emails/${this.email._id}`,
            {
              subjectLine: this.email.subjectLine,
              content: this.email.content,
              sendAfter: this.email.sendAfter,
              replyInThread: this.email.replyInThread,
              includeSignature: this.email.includeSignature,
              campaignId: this.campaignId,
              emailNumber: this.email.emailNumber,
            }
          );
        }
        this.$emit("emailSaved", response.data);
      } catch (err) {
        this.$emit("failedSavingEmail", err);
      }
    },
    getSendAfterInSeconds(sendAfterTime) {
      if (this.timeSelection === "Minutes") {
        return sendAfterTime * 60;
      } else if (this.timeSelection === "Hours") {
        return sendAfterTime * 60 * 60;
      } else {
        return sendAfterTime * 60 * 60 * 24;
      }
    },
  },
};
</script>

<style lang="scss">
.tiptap-vuetify-editor .v-card {
  box-shadow: none !important;
  border: 1px solid #eee;
}
</style>
